<template>
  <div v-loading="loading">
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会员答题</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/audioBooks' }"
          >游戏</el-breadcrumb-item
        >
        <el-breadcrumb-item>编辑关卡</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-form
      :rules="rules"
      ref="form"
      :model="form"
      style="padding: 48px 72px"
      label-position="right"
      label-width="auto"
    >
      <el-form-item label="关卡名称" prop="name">
        <el-input
          v-model="form.name"
          style="width: 240px"
          autofocus
          clearable
        />
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input-number
          v-model="form.sort"
          :min="1"
          size="mini"
          style="width: 240px"
        ></el-input-number>
      </el-form-item>

      <el-form-item
        label="选择题库"
        label-position="left"
        prop="question_bank_id"
      >
        <edit-question-bank
          :questionBankName="questionBankName"
          @onSelect="onSelectItem"
          @onDelete="onDeleteItem"
        ></edit-question-bank>
      </el-form-item>

      <el-form-item>
        <el-button size="mini" @click="$router.back()">取消</el-button>
        <el-button
          size="mini"
          :loading="bottomLoading"
          @click="submit"
          type="primary"
          >确定</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import editQuestionBank from "@/components/editQuestionBank";
export default {
  components: { editQuestionBank },
  data() {
    return {
      loading: false,
      bottomLoading: false,
      rules: {
        name: [{ required: true, message: "请输入关卡名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        question_bank_id: [
          { required: true, message: "请选择题库", trigger: "change" },
        ],
      },
      form: {
        question_bank_id: null,
      },
      id: 0,
      questionBankName: "",
    };
  },

  methods: {
    ...mapActions("game", ["gameAddPass", "gameGetPass"]),
    onSelectItem(e) {
      this.form.question_bank_id = e;
    },
    onDeleteItem() {
      this.form.question_bank_id = "";
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        else {
          this.bottomLoading = true;
          const { data, res_info } = await this.gameAddPass(this.form);
          this.bottomLoading = false;
          this.$router.go(-1);
          if (res_info != "ok") return;
          this.$message.success("编辑成功！");
        }
      });
    },
    async initData() {
      this.loading = true;
      const { data } = await this.gameGetPass(this.id);
      console.log(data);
      this.form = data;
      this.questionBankName = data.question_bank_name;
      this.loading = false;
    },
  },
  mounted() {
    this.form.games_id = this.$route.params.games_id;
    this.id = this.$route.params.id;
    this.initData();
  },
};
</script>
